import { useState, useRef, useEffect } from 'react'
import va from '@vercel/analytics'

import LeftArrow from '@components/Icons/LeftArrow.jsx'
import RightArrow from '@components/Icons/RightArrow.jsx'

import { splitLastOccurrence } from '@utils/utils.js'

const Carousel = ({ carouselData, aspect = '2/3', carouselType }) => {
    const data = carouselData
    //

    let dataItems

    if (data && data?.items && data.items.length > 0) {
        dataItems = data.items
    } else {
        dataItems = data
    }

    const maxScrollWidth = useRef(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const carousel = useRef(null)
    const totalSlides = dataItems.length
    const [scrollX, setScrollX] = useState(0)
    const [scrollEnd, setScrollEnd] = useState(false)

    // let tileWidthClass = `${aspect === 'square' ? 'w-[200px] md:w-[300px]' : 'w-[200px] md:w-[300px] height-[300px] md:height-[450px]'}`

    let tileWidthClass = ''

    const movePrev = () => {
        if (carousel.current !== null) {
            let child
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1
            if (window.innerWidth > 800) {
                carousel.current.scrollLeft -= frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft -= frameWidth * 2 + 10
            }
            // setCurrentIndex((prevState) => prevState + 1)
        }
    }

    const moveNext = () => {
        if (carousel.current !== null) {
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1

            //
            //
            //
            //
            if (window.innerWidth > 800) {
                carousel.current.scrollLeft += frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft += frameWidth * 2 + 10
            }
        }
    }

    useEffect(() => {
        maxScrollWidth.current = carousel.current
            ? carousel.current.scrollWidth - carousel.current.offsetWidth
            : 0
    }, [])

    const scrollFunction = (e) => {
        setScrollX(carousel.current.scrollLeft)
        if (
            Math.floor(
                carousel.current.scrollWidth - carousel.current.scrollLeft
            ) <= carousel.current.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }

    //

    return (
        <div className="carousel-outer max-w-[90vw] 2xl:max-w-[1400px] mx-auto">
            <div className="carousel-inner wrap-[90vw] overflow-x-hidden lg:wrap-xl mx-auto">
                <div className="carousel mx-auto wrap-[90vw] overflow-x-hidden lg:wrap-xl relative">
                    <div className="carousel-header relative flex flex-col lg:flex-row items-center mb-3 mr-4">
                        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl max-w-[400px]">
                            {data && data.title ? data.title : 'Categories'}
                        </h2>
                        <div className="carousel-header-wrap justify-center lg:justify-end lg:ml-auto flex flex-col lg:flex-row items-center gap-3">
                            {data.type !== 'classes' &&
                            data.type !== 'classes_posts' ? (
                                <div className="shop-all-links flex items-center gap-3">
                                    <div className="shop-all-link">
                                        <a
                                            href={
                                                data && data.link
                                                    ? data.link
                                                    : '/collections/baby-clothes'
                                            }
                                            className="text-fl-sm underline lg:mr-3"
                                        >
                                            {data && data.linkText
                                                ? data.linkText
                                                : ''}
                                        </a>
                                    </div>
                                </div>
                            ) : data.type === 'classes_posts' ? (
                                <div className="shop-all-links flex items-center gap-3">
                                    <div className="shop-all-link text-sm underline ">
                                        <a href="/pages/classes-and-events">
                                            All Classes + Events
                                        </a>
                                    </div>
                                    <div className="shop-all-link text-sm underline ">
                                        <a href="/blogs/ma-edit/all-posts">
                                            All Blog Posts
                                        </a>
                                    </div>
                                </div>
                            ) : null}
                            <div className="carousel-nav flex items-center gap-3">
                                <div
                                    className={`angle-left ${
                                        scrollX === 0
                                            ? 'cursor-not-allowed opacity-50'
                                            : 'cursor-pointer'
                                    }`}
                                    onClick={() => movePrev()}
                                    disabled={scrollX === 0}
                                >
                                    <LeftArrow width="w-[32px]" />
                                </div>
                                <div
                                    className={`angle-right ${
                                        scrollEnd
                                            ? 'cursor-not-allowed opacity-50'
                                            : 'cursor-pointer'
                                    }`}
                                    onClick={() => moveNext()}
                                    disabled={scrollEnd}
                                >
                                    <RightArrow width="w-[32px]" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-x-hidden">
                        <div
                            onScroll={(e) => scrollFunction(e)}
                            ref={carousel}
                            className="carousel-container relative grid grid-flow-col auto-cols-[minmax(200px,1fr)] lg:auto-cols-[minmax(300px,1fr)] gap-1 overflow-x-scroll scroll-smooth z-0"
                        >
                            {data &&
                                dataItems &&
                                dataItems.map((item, index) => {
                                    let imageUrl = item.imageUrl
                                    //
                                    const [before, after] = splitLastOccurrence(
                                        imageUrl,
                                        '.'
                                    )
                                    let resizedImageUrl =
                                        before + '_400x600.' + after
                                    return (
                                        <div
                                            key={index}
                                            className={`carousel-item relative h-auto ${
                                                item.itemType === 'class' ||
                                                item.itemType === 'post'
                                                    ? 'class-item aspect-square'
                                                    : 'product-item aspect-[2/3]'
                                            } ${
                                                item.itemType === 'collection'
                                                    ? 'collection-item aspect-[2/3]'
                                                    : ''
                                            }`}
                                        >
                                            <a
                                                href={item.slug}
                                                className={`
                                                     h-auto ${
                                                         item.itemType ===
                                                             'class' ||
                                                         item.itemType ===
                                                             'post'
                                                             ? 'class-item aspect-square hover:opacity-80 object-cover'
                                                             : 'product-item aspect-[2/3]  object-cover'
                                                     } block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 relative object-cover hover:opacity-80`}
                                                // style={{
                                                //     backgroundImage: `url(${
                                                //         resizedImageUrl || ''
                                                //     })`,
                                                // }}
                                                aria-label={`${
                                                    item.itemName
                                                        ? item.itemName +
                                                          ' category page'
                                                        : 'category page'
                                                }`}
                                                onClick={() => {
                                                    va.track(
                                                        'Carousel Tile Click',
                                                        {
                                                            itemType:
                                                                item.itemType,
                                                            itemName:
                                                                item.itemName,
                                                            itemLink: item.slug,
                                                        }
                                                    )
                                                }}
                                            >
                                                {item.itemType &&
                                                item.itemType === 'class' ? (
                                                    <div className="text-xs triangle-class">
                                                        <span className="text-white">
                                                            class
                                                        </span>
                                                    </div>
                                                ) : null}
                                                {item.itemType &&
                                                item.itemType === 'post' ? (
                                                    <span className="text-xs triangle-post">
                                                        <span className="text-ash">
                                                            post
                                                        </span>
                                                    </span>
                                                ) : null}
                                                {item.itemType &&
                                                item.itemType ===
                                                    'blog-post' ? (
                                                    <span className="text-xs triangle-post">
                                                        <span className="text-ash">
                                                            article
                                                        </span>
                                                    </span>
                                                ) : null}
                                                <img
                                                    src={resizedImageUrl || ''}
                                                    alt={item.itemName}
                                                    loading="lazy"
                                                    className={`w-full ${
                                                        item.itemType ===
                                                            'class' ||
                                                        item.itemType === 'post'
                                                            ? 'class-item aspect-square object-cover'
                                                            : 'product-item aspect-[2/3] object-cover'
                                                    } `}
                                                />
                                                <span className="sr-only">
                                                    {item.itemName}
                                                </span>

                                                <span className="sr-only">
                                                    {item.itemName
                                                        ? item.itemName
                                                        : ''}
                                                </span>
                                            </a>

                                            {/* <div>
                                                {item.itemCat && (
                                                    <div className={`category-pill px-3 py-1 text-xs text-flint inline-block text-left mt-2 ${item.itemType === 'class' ? 'bg-oat/50' : 'bg-slate' }`}>
                                                        {item.itemCat}
                                                    </div>
                                                )}

                                                <h3
                                                    className={`mx-auto text-base text-left z-20 min-h-[60px] pr-4 ${
                                                        item.itemType ===
                                                            'class' ||
                                                        item.itemType ===
                                                            'post' ||
                                                        item.itemType ===
                                                            'blog-post'
                                                            ? 'hidden'
                                                            : 'absolute thisone left-2 bottom-3 text-white py-6 px-3 line-clamp-2'
                                                    }`}
                                                >
                                                    {item.itemName.replace(
                                                        '_',
                                                        ' - '
                                                    )}
                                                </h3>
                                            </div> */}

                                            {item.itemType &&
                                            item.itemType === 'class' ? (
                                                <a
                                                    href={item.slug}
                                                    className="mt-2 text-sm hover:underline text-flint leading-[1.3] line-clamp-2"
                                                >
                                                    {item.itemName}
                                                </a>
                                            ) : null}
                                            {item.itemType &&
                                            item.itemType === 'post' ? (
                                                <a
                                                    href={item.slug}
                                                    className="mt-2 text-sm hover:underline leading-[1.3] line-clamp-2"
                                                >
                                                    {item.itemName}
                                                </a>
                                            ) : null}
                                            {item.itemType &&
                                            item.itemType === 'blog-post' ? (
                                                <a
                                                    href={item.slug}
                                                    className="text-sm underline font-thin"
                                                >
                                                    + learn
                                                </a>
                                            ) : null}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousel
